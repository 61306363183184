<template>
    <div class="text-center hovercursor" @click="onClickImportFile">
        <v-img
            height="128"
            width="128"
            :src="profilePicThumbnailUrl || 'https://placehold.co/200x200?text=Upload+Photo'"
            class=" rounded-circle"
            alt=""
            style="margin: auto;"
        ></v-img>                            
        <div style="font-size: 0.8em">
            Click image to change
        </div>   
        <input
            id="import-ele-input_file"
            :accept="fileExtns"
            type="file"
            style="display: none;"
          >                         
    </div>
  </template>
  
  <script>
  export default {
    name: "FileUploaderPhoto",
    props: {
      height: {
        type: String,
        required: false,
        default: () => '50vh'
      },
      multiple: {
        type: Boolean,
        required: false,
        default: () => true
      },
      fileExtns: {
        type: Array,
        required: false,
        default: () => []
      },
      profilePicThumbnailUrl: {
        type: String,
        required: false,
        default: () => 'https://placehold.co/200x200?text=Upload+Photo'
      },
    },
    methods: {
      onDrop(e) {
        e = e || window.event
        e.preventDefault()
        const files = []
        if (e.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (let index = 0; index < e.dataTransfer.items.length; index++) {
            if (e.dataTransfer.items[index].kind === 'file') {
              files.push(e.dataTransfer.items[index].getAsFile())
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          for (let index = 0; index < e.dataTransfer.files.length; index++) {
            files.push(e.dataTransfer.files[index])
          }
        }
        this.$emit('files', files)
      },
      onDragOver(e) {
        e.preventDefault()
      },
      onClickImportFile () {
        const inputELe = document.getElementById('import-ele-input_file')
        inputELe.setAttribute('type', 'file')
        if (this.multiple) {
          inputELe.setAttribute('multiple', '')
        }
        inputELe.addEventListener('change', e => {
          e = e || window.event
          this.$emit('files', e.target.files)
        })
        inputELe.click()
      }
    }
  }
  </script>
  
  <style scoped>
    .highlight-area {
      border-style: dashed;
      border-color: rgb(185, 185, 185);
      border-width: 3.2px;
      padding: 12px 18px;
    }

    .hovercursor {
      cursor: pointer;
    }
  </style>