<template>
    <v-list outlined style="border-radius: 20px; " class="mb-4">
        <v-list-item>
            <v-list-item-content class="pb-0" style="display: inline">
                <h5 v-if="value.title" style="display: inline; vertical-align: middle;">{{ value.title }}</h5>
                <h5 v-else style="display: inline; vertical-align: middle;">{{ $t("location_comp.area") }}</h5>    
            </v-list-item-content>
        </v-list-item>        
        <v-list-item>
            <v-list-item-content class="pb-0">
                <span>{{ $t("location_comp.drag bar handle") }}</span>
            </v-list-item-content>                                        
        </v-list-item> 
        <v-list-item>
            <v-list-item-content class="pb-0 pt-9" style="display: inline">                   
                <v-slider                    
                    v-model="radius"
                    :label="(radius / 1000).toString() + 'km'"
                    step="2000"
                    min="2000"
                    max="100000"                    
                    ticks                               
                ></v-slider>           
            </v-list-item-content>
        </v-list-item>        

        <v-list-item>
            <v-list-item-content class="pb-0">
                <span>{{ $t("location_comp.drag circle to change position or search for a new address") }}</span>
            </v-list-item-content>                                        
        </v-list-item> 
        <v-list-item>
            <v-list-item-content class="pb-0">
                <!--<google-map :pois="pois" :draggable="true" :zoom="18" @ondrag="markerdragged" />-->
                <GoogleMapArea @onresult="addressChanged" :pinCoords="{lat: value.latcoord, lng: value.lngcoord}" :radius="radius" />
            </v-list-item-content>                                        
        </v-list-item>  
        <v-list-item v-if="!coordsValState">
            <v-list-item-content class="pb-0">
                <v-alert                
                    color="red"
                    dark
                    type="error"
                >
                {{ $t("location_comp.map selection required") }}
                </v-alert>
            </v-list-item-content>                                        
        </v-list-item> 
        <!--<v-list-item>
            <v-list-item-content class="pb-0">
                <div>
                    <v-btn 
                        depressed
                        color="primary"
                        x-small
                        v-if="!value.primary_location"
                        @click="$router.push('/')"                    
                    >
                        <v-icon left>
                            mdi-map-marker-check
                        </v-icon>
                        {{ $t("location_comp.set as default") }}
                    </v-btn>        
                </div>            
            </v-list-item-content>
        </v-list-item>     -->
        <v-list-item>
            <v-list-item-content class="pb-0">                
                <v-text-field 
                    label="Title" 
                    v-model="value.title"    
                    :error-messages="titleValState"         
                    :validate-on-blur="false"
                    class="col-12 p-0 pr-4"
                    style="padding: 0px 0px;"                            
                />
            </v-list-item-content>
        </v-list-item>  
        <!-- <v-list-item>
            <v-list-item-content class="pb-0">
                <h6>Contact</h6>
            </v-list-item-content>
        </v-list-item>                              
        <v-list-item>                                        
            <v-list-item-content class="pb-0">                                            
                <v-text-field 
                    label="Phone No." 
                    v-model="value.phoneno"    
                    :rules="[]"
                    :validate-on-blur="false"
                    class="col-6 p-0 pr-4"
                    style="padding: 0px 0px;"
                />                                   
                <v-text-field 
                    label="Email" 
                    v-model="value.email"    
                    :rules="[]"
                    :validate-on-blur="false"
                    class="col-6 p-0 pr-4"
                    style="padding: 0px 0px;"
                />
                
            </v-list-item-content>
        </v-list-item>  -->
    </v-list>      
    </template>
    
    <script>
    import { api } from "src/api/index";
    import moment from "moment";
    import axios from "axios";    
    import GoogleMapArea from "@/components/core/GoogleMapArea";
    
    import _ from "lodash";
    
    import { mapGetters, mapActions } from "vuex";
    
    export default {
        // metaInfo: {
        //     // title will be injected into parent titleTemplate
        //     title: 'Professional Setip'
        // },    
        components: {                    
            GoogleMapArea
        },
        model: {
            prop: "value",
            event: "change"
        },
        // props: ['ttindex', 'modelValue'],
        //props: ['index', 'value'],
    
        props: {
            index: Number,
            loading_setDefaultLocation: Boolean,
            value: {
                type: Object,
                default: () => ({
                    code: null,
                    
                    position: null,
                    title: undefined,
           
                    // phoneno: undefined,
                    // email: undefined,
                    latcoord: undefined,
                    lngcoord: undefined,
                    valstate: undefined,
                }),
            },
            // valstate: {
            //     type: Object
            // }
        },
    
        // props: {
        //     ttindex: null,
        //     modelValue: null,
        // },
    
        
        
        data() {
            return {            
                searchaddress: null,
    
                code: this.value.code,
                address: this.value.address,                                
                title: this.value.title,
                // phoneno: this.value.phoneno,
                // email: this.value.email,                
                latcoord: this.value.latcoord,
                lngcoord: this.value.lngcoord,        
                
                valstate: this.value.valstate || null,
                
                loading: false,
                addressresults: [],
                resultselected: false,
                radius: 1000
            }
        },    
        computed: {
            titleValState() {
                return this.value.valstate?.title?.msg;
            },                                                
            coordsValState() {
                if(this.value.hasOwnProperty("valstate")) {
                    if (this.value.valstate?.hasOwnProperty("coords")) {
                        return this.value.valstate?.coords?.valid;
                    }
                }                        
                return true;
            },
            // pois() {
            //     if (typeof this.address === 'object' && this.address !== null) {
            //         return [{
            //             position: {
            //                 lat: this.address.data.lat,
            //                 lng: this.address.data.lng
            //             }
            //         }]
            //     }
            //     else {
            //         return [];
            //     }
            // }
        },
        methods: {
            ...mapActions([]),
            addressChanged: function (addressInfo) {
                console.log("circle drag: " + JSON.stringify(addressInfo));
                //alert(JSON.stringify(addressInfo));
                if(addressInfo.lat) {
                    this.value.latcoord = addressInfo.lat;
                }
                if(addressInfo.lng) {
                    this.value.lngcoord = addressInfo.lng;
                }
                                             
                // if(addressInfo.country) {
                    
                // }
            },
        },
        async created() {
        },
        async mounted() {
            
        }
    }
    </script>
    <style lang="scss" scoped>
    
    </style>
    